<template>
	<div class="pui-form">
		<mshiptypes-modals :modelName="modelName"></mshiptypes-modals>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('mdocks.fieldset.dockdata')">
						<v-row dense>
							<!-- SHIPTYPECODE -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-text-field
									:id="`shiptypecode-mshiptypes`"
									v-model="model.shiptypecode"
									:label="$t('mshiptypes.shiptypecode')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-text-field
									:id="`desc-mshiptypes`"
									v-model="model.shiptypedesc"
									:label="$t('mshiptypes.desc')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mshiptypes-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'mshiptypes'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
